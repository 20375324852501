import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const allSignaturesQuery = graphql`
query allSignatures {
  dataJson {
    signatures {
      type
      slug
      name
    }
  }
}
`

const Title = {
  textAlign: 'center',
  fontFamily: 'Arial',
  textTransform: 'uppercase'
}
const SignatureWrapper = {
  display: 'flex',
  fontFamily: 'Arial',
  justifyContent: 'center'
}
const Didacticiel = {
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Arial',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px'
}
const DidacticielOlLi = {
  marginBottom: '5px'
}
const LinkStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '10vw',
  width: '10vw',
  backgroundColor: 'rgba(0,0,0,0.1)',
  margin: '0 10px',
  padding: '20px',
  textDecoration: 'none',
  textAlign: 'center',
  color: 'red',
  fontWeight: 'bold',
  borderBottom: 'solid 2px red'
}


const IndexPage = () => (
  <StaticQuery
  query={allSignaturesQuery}
  render={data => (
    <>
      <h1 style={Title}>B2A</h1>
      <div style={SignatureWrapper}>
        {data.dataJson.signatures.map(((signature, index) => (
          <div key={index}>
            <Link
              style={Object.assign({}, LinkStyle, {
                borderBottomColor: "#952EF8",
                color: "#952EF8"
              })}
              to={`./signatures/${signature.slug}`}>
              {signature.name}<br/>
              {signature.type}
            </Link>
          </div>
        )))}
      </div>

      <div class="didacticiel" style={Didacticiel}>
        <span>Choisissez le type signature correspondante et copiez/collez la dans votre client mail. </span>
        <span>Une fois collé dans votre client mail, veuillez modifier les informations en suivant les étapes ci-dessous : </span>
        <ol>
          <li style={DidacticielOlLi}>Modifier le nom et le prénom</li>
          <li style={DidacticielOlLi}>Modifier le poste</li>
          <li style={DidacticielOlLi}>Modifier l’adresse de votre laboratoire</li>
          <li style={DidacticielOlLi}>Modifier le numéro de téléphone</li>
          <li style={DidacticielOlLi}>Modifier le lien du numéro de téléphone</li>
        </ol>
      </div>
    </>
  )} />
)

export default IndexPage
